@import '../../../variables.css';
@import 'https://cdnjs.cloudflare.com/ajax/libs/Swiper/7.4.1/swiper-bundle.min.css';

:root {
    --leftPanelAndMargins: 122px;
    --margins: 32px;
}

.sliderContainer {
    width: 100%;
    height: auto;
    position: relative;
}

.swiperContainer {
    overflow: hidden;
    /*For Early Access games*/
    padding: 3px 0 3px 3px;
}

.listItem {
    height: auto !important;
    flex-shrink: 0;
    width: auto !important;
    margin-right: 16px;
}

[data-variations='C'] .listItem {
    margin-right: 44px;
}

.listItem:first-child {
    margin-left: 0;
}

.leftShadow,
.rightShadow {
    position: absolute;
    top: 0;
    bottom: 0;
    height: 100%;
    width: 5rem;
    opacity: 0;
    transition: opacity 0.531s linear;
    pointer-events: none;
    z-index: 3;
}

.leftShadow {
    left: 0;
    background-image: linear-gradient(to left, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
}

.rightShadow {
    right: 0;
    background-image: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
}

[data-variations='C'] .leftShadow {
    left: 3px;
    top: 3px;
    background-image: linear-gradient(90deg, #1e2a79 0%, rgba(30, 42, 121, 0) 100%);
    transform: rotate(0);
    width: 140px;
    height: 338.9px;
    border-radius: 0.625rem;
}

[data-variations='C'] .rightShadow {
    right: 32px;
    top: 3px;
    background-image: linear-gradient(-90deg, #1e2a79 0%, rgba(30, 42, 121, 0) 100%);
    width: 140px;
    height: 338.9px;
    border-radius: 0.625rem;
}

.toLeftButton,
.toRightButton {
    width: 5rem;
    height: 5rem;
    border-radius: 50%;
    background: none;
    border: none;
    box-shadow: none;
    position: absolute;
    top: calc(50% - 1.5rem);
    right: 0;
    transform: translateY(-50%);
    z-index: 4;
    cursor: pointer;
    opacity: 0;
    transition: opacity 0.531s linear;
    pointer-events: none;
}

[data-variations='C'] .toRightButton {
    right: 30px;
    transform: translateY(-50%) translateX(-30%);
}

[data-variations='C'] .toLeftButton {
    left: 30px;
    transform: translateY(-50%);
}

.toRightButton {
    transform: translateY(-50%) translateX(12%);
}

.toLeftButton {
    left: 0;
    transform: translateY(-50%);
}

.sliderContainer.hasLeftControl .toLeftButton,
.sliderContainer.hasLeftControl .leftShadow {
    opacity: 1;
    pointer-events: all;
}

.sliderContainer.hasRightControl .toRightButton,
.sliderContainer.hasRightControl .rightShadow {
    display: block;
    opacity: 1;
    pointer-events: all;
}

.captionAndLink {
    margin-bottom: 0.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.pagination {
    position: absolute;
    display: flex;
    justify-content: center;
    bottom: 1rem;
    left: 0;
    width: 100%;
    z-index: 10;
}

.bullet {
    display: block;
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
    background-color: var(--ark-neutral-white-80);
    transition: background-color 0.15s ease;
}
.bullet:not(:last-child) {
    margin-right: 0.5rem;
}

.bulletActive {
    background-color: var(--ark-neutral-white);
}

.sliderContainer.homePageBanner .toLeftButton,
.sliderContainer.homePageBanner .toRightButton {
    display: none !important;
}

@media (min-width: var(--ark-small-desktop-figma-1024)) {
    .sliderContainer .pseudoTile img:hover {
        transform: translate(0) scale(1.1);
    }

    .sliderContainer .pseudoTile img {
        transition: transform 0.3s;
        transform: translate(0);
        left: 0;
        top: 0;
        z-index: 10;
    }
}

@media (max-width: var(--ark-small-desktop-figma-1024)) {
    [data-variations='C'] .leftShadow {
        width: 150px;
        pointer-events: none !important;
    }

    [data-variations='C'] .rightShadow {
        width: 150px;
        pointer-events: none !important;
    }

    .toLeftButton,
    .toRightButton {
        display: none !important;
    }

    .sliderContainer .listItem:first-child {
        margin-left: 1rem;
    }

    .promoSlider .listItem:first-child {
        margin-left: 0;
    }

    .sliderContainer.homePageBanner {
        width: calc(100vw - var(--leftPanelAndMargins));
        margin-left: 16px;
    }

    .sliderContainer.homePageBanner .listItem {
        margin-left: 0;
        margin-right: 0;
    }
}

@media (max-width: var(--ark-small-mobile-below-575)) {
    .sliderContainer.hasLeftControl .leftShadowMobile {
        opacity: 0;
    }
}

@media (max-width: 1024px) and (orientation: portrait) {
    .sliderContainer.homePageBanner {
        /* width: calc(100vw - var(--margins)); */
        width: 100vw;
        margin-left: 0;
    }
}

@media (max-width: var(--ark-small-desktop-figma-1024)) {
    /*Workaround for SEO according to Google Lighthouse*/
    .bullet {
        min-height: var(--ark-minSize-for-interactive-el);
        min-width: var(--ark-minSize-for-interactive-el);
        visibility: hidden;
    }
    .bullet:before {
        visibility: visible;
        background-color: rgba(255, 255, 255, 0.8);
        content: '';
        height: 1rem;
        width: 1rem;
        position: absolute;
        border-radius: 50%;
        bottom: 0;
        transform: translate(70%, 20%);
    }
    .bullet.bulletActive:before {
        background-color: rgb(255, 255, 255);
    }
}
