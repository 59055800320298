@import "../../../../../variables.css";

.arrowIconContainer {
    width: 4rem;
    height: 4rem;
    border-radius: 50%;
    border: 1px solid var(--ark-slider-button);
    box-shadow: 0 0 5px var(--ark-slider-button);
    position: relative;
    background: white;
    overflow: hidden;
    color: var(--ark-slider-button);
    z-index: 100;
    transition: color 0.531s var(--ark-ease-in-out-sine);
}

.arrowIconContainer::before {
    content: ' ';
    position: absolute;
    width: 4rem;
    height: 4rem;
    background-color: var(--ark-slider-button);
    top: 0;
    left: 0;
    transform: translateX(-100%);
    transition: transform 0.531s var(--ark-ease-in-out-sine);
}

.arrowIconContainer::after {
    /* content: ' ';
    position: absolute;
    width: 4rem;
    height: 4rem;
    background-color: var(--ark-neutral-white);
    top: 0;
    left: 0;
    transform: translateX(100%);
    transition: transform 0.531s var(--ark-ease-in-out-sine); */
}

.arrowIconContainer:hover::before {
    transform: translateX(0%);
}

.arrowIconContainer:active {
    /* color: var(--ark-slider-button) !important; */
}

.arrowIconContainer:active::after {
    /* transform: translateX(-100%);
    transition: 0s; */
}

.arrowIconContainer:hover {
    color: white;
}

.arrowIcon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;
}

.leftOriented .arrowIcon {
    transform: translate(-50%, -50%) rotate(-180deg);
}
